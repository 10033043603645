import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import { UpdatePassword } from '../components/UpdatePassword'
import { isLoggedIn } from '../utils/protectedRoute'
const UpdatePasswordPage = ({ location }) => {
	return !isLoggedIn() ? (
		<h1>you are not loggedIn </h1>
	) : (
		<Layout>
			<SEO title='Update Password' />
			<UpdatePassword />
		</Layout>
	)
}

export default UpdatePasswordPage
