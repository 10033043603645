import React, { useState } from 'react'
import * as Yup from 'yup'
import './style.css'
import { Formik, Field, Form } from 'formik'
import { changePasswordMutation } from './graphql/mutations'
import { useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify'
import { navigate } from 'gatsby'
import jwtDecode from 'jwt-decode'
import { isBrowser } from '../../utils/general'

const ResetPasswordValidationScheme = Yup.object().shape({
	currentpassword: Yup.string()
		.required('Please enter password')
		.min(5)
		.max(255),
	password: Yup.string()
		.required('Please enter password')
		.min(5)
		.max(255)
		.label('Password'),
	cpassword: Yup.string()
		.oneOf([Yup.ref('password'), null], "Passwords don't match")
		.required('Please Confirm Password')
})

const intialValue = {
	currentpassword: '',
	password: '',
	cpassword: ''
}

const UpdatePassword = ({ state }) => {
	const [changePasswordMutationTrigger, { data, error, loading }] = useMutation(
		changePasswordMutation
	)

	const handleChangePassword = ({ currentpassword, password }) => {
		let token = isBrowser() ? JSON.parse(localStorage.getItem('token')) : {}
		let sessionToken = isBrowser()
			? JSON.parse(sessionStorage.getItem('token'))
			: {}
		if (token || sessionToken) {
			//now decoding the token
			const { _id } = jwtDecode(token ? token : sessionToken)

			//executing the mutation

			changePasswordMutationTrigger({
				variables: {
					id: _id,
					data: {
						password: currentpassword,
						newPassword: password
					}
				}
			})
		}
	}
	if (error) {
		toast.error(error.graphQLErrors[0].message.message, {
			autoClose: 1200
		})
	}
	if (data) {
		toast.success('Password Change Successfully', {
			autoClose: 1200
		})
		setTimeout(() => {
			navigate('/my-account')
		}, 1200)
	}
	return (
		<div className='container-fluid d-flex hm-login-bg justify-content-center align-items-center'>
			<div id='forgotPassword'>
				<Formik
					initialValues={intialValue}
					validationSchema={ResetPasswordValidationScheme}
					onSubmit={values => handleChangePassword(values)}
				>
					{({ values, errors, touched, handleSubmit }) => (
						<div className='inputstyleview'>
							<Form className='formStyle'>
								<div className='forgotPasswordTextView'>
									<p>Update Password</p>
								</div>
								<Field
									className='inputStyle'
									type='password'
									name='currentpassword'
									placeholder='Enter Current Password'
								/>
								{errors.currentpassword && touched.currentpassword ? (
									<div className='bg-danger my-1 p-0 col-12 rounded'>
										<p className='text-white p-1 m-0'>
											{errors.currentpassword}
										</p>
									</div>
								) : null}
								<Field
									className='inputStyle'
									type='password'
									name='password'
									placeholder='Enter New Password'
								/>
								{errors.password && touched.password ? (
									<div className='bg-danger my-1 p-0 col-12 rounded'>
										<p className='text-white p-1 m-0'>{errors.password}</p>
									</div>
								) : null}
								<Field
									className='inputStyle'
									type='password'
									name='cpassword'
									placeholder='Repeat New Password'
								/>
								{errors.cpassword && touched.cpassword ? (
									<div className='bg-danger my-1 p-0 col-12 rounded'>
										<p className='text-white p-1 m-0'>{errors.cpassword}</p>
									</div>
								) : null}
							</Form>
							<div onClick={handleSubmit} className='loginButtonStyle'>
								<p className='loginButtonText'>
									{loading ? <div className='spinner'></div> : 'Update'}
								</p>
							</div>
						</div>
					)}
				</Formik>
			</div>
		</div>
	)
}

export { UpdatePassword }
