import { isBrowser } from './general'

export const isLoggedIn = () => {
	let token = isBrowser() ? JSON.parse(localStorage.getItem('token')) : {}
	let sessionToken = isBrowser()
		? JSON.parse(sessionStorage.getItem('token'))
		: {}

	if (token || sessionToken) {
		return true
	}
}
